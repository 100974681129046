
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.PaymentSummary', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.paymentSummary', {
    url: '/payment-summary',
    component: 'registerPaymentSummaryRoute',
  });
})

/**
 * Route component
 */
.component('registerPaymentSummaryRoute', {
  controller: 'RegisterPaymentSummaryCtrl',
  templateUrl: 'portal/register/steps/payment-summary.html',
  bindings: {
    club: '<',
    transition: '<',
    registration: '<',
    payment: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterPaymentSummaryCtrl', function($controller, $scope, Config) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Flag as loading
    this.isLoading = true;

    //Get data
    this.payment
      .getChargeAndFee()
      .finally(() => this.isLoading = false);

    setTimeout(() => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.render('captcha-container', {
          sitekey: Config.google.recaptchaKey,
          callback: this.captchaCallback.bind(this),
          action: 'signup',
        });
      });
    });
  };

  this.captchaCallback = function(response) {
    this.registration.setCaptchaResponse(response);
    $scope.$apply(); //Force update
  };

  /**
   * Post registration handler
   */
  this.postRegistration = function() {

    //Set flag
    this.isRedirecting = false;

    //Get registration token
    const {token, requiresApproval} = this.registration;

    //Setup payment service
    this.payment.setExtraData({token});
    this.payment.setRedirectPath(`register/payment-redirect?requiresApproval=${requiresApproval}`);

    //Initiate payment
    return this.payment
      .initiatePayment()
      .then(outcome => {

        //Redirecting
        if (outcome.isRedirecting) {
          this.isRedirecting = true;
          return;
        }

        //Done paying, finish registration now
        this.registration.finish();
      })
      .catch(error => {
        this.isRedirecting = false;

        //Ignore card errors
        if (typeof error === 'object' && error.type === 'card_error') {
          return;
        }

        //Ignore cancel error
        if (typeof error === 'string' && error === 'cancel') {
          return;
        }

        //Rethrow other errors
        throw error;
      });
  };
});
