
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Confirmation', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.confirmation', {
    url: '/confirmation',
    component: 'registerConfirmationRoute',
  });
})

/**
 * Route component
 */
.component('registerConfirmationRoute', {
  controller: 'RegisterConfirmationCtrl',
  templateUrl: 'portal/register/steps/confirmation.html',
  bindings: {
    club: '<',
    registration: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterConfirmationCtrl', function($controller, $scope, Config) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  this.setup = function() {
    setTimeout(() => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.render('captcha-container', {
          sitekey: Config.google.recaptchaKey,
          callback: this.captchaCallback.bind(this),
          action: 'signup',
        });
      });
    });
  };

  this.captchaCallback = function(response) {
    this.registration.setCaptchaResponse(response);
    $scope.$apply(); //Force update
  };
});
