
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.Memberships', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.memberships', {
    url: '/memberships',
    component: 'registerMembershipsRoute',
  });
})

/**
 * Route component
 */
.component('registerMembershipsRoute', {
  controller: 'RegisterMembershipsCtrl',
  templateUrl: 'portal/register/steps/memberships.html',
  bindings: {
    club: '<',
    memberships: '<',
    registration: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterMembershipsCtrl', function(
  $controller, $modal, Settings, Subscription
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Init
   */
  this.$onInit = async function() {

    //Base init
    $base.$onInit.call(this);

    //Get data
    const {registration, memberships} = this;
    const {primaryMember, secondaryMembers, preSelectedMembership} = registration;

    //Add pre-selected membership to primary member
    if (preSelectedMembership) {

      //Find membership
      const membership = this.getEligibleMemberships(primaryMember).find(m => m.id === preSelectedMembership);
      const num = await membership?.getNumCurrentSubs();
      if (membership && (!membership.limit || num < membership.limit)) {

        //Get primary member details
        const {age, address} = primaryMember;

        //Load primary sub
        this.isLoadingSub = true;
        Subscription
          .getRegisterData(preSelectedMembership, age, address)
          .then(data => {
            primaryMember.memberships.push(membership);
            primaryMember.subscriptions.push(data.subscriptions[0]);
            registration.determineSteps();
          })
          .finally(() => this.isLoadingSub = false);

        //Check if we can pre-select membership for secondary members
        if (membership.hasLinked && membership.linked.length === 1) {

          //Get linked membership
          const linked = memberships.find(m => m.id === membership.linked[0].id);
          const {hasAgeRestriction, enforceSameHousehold, bundleLimit} = linked;
          if (!hasAgeRestriction) {

            //Loop secondary members
            let added = 0;
            for (const secondary of secondaryMembers) {
              const {age, address, isSameHousehold} = secondary;

              //Validate
              if (enforceSameHousehold && !isSameHousehold) {
                continue;
              }

              //Add linked member
              Subscription
                .getRegisterData(linked.id, age, address)
                .then(data => {
                  secondary.memberships.push(linked);
                  secondary.subscriptions.push(data.subscriptions[0]);
                  registration.determineSteps();
                });

              //Add
              added++;

              //Reached limit
              if (bundleLimit && added === bundleLimit) {
                break;
              }
            }
          }
        }
      }
    }
  };

  /**
   * Has eligible memberhip types
   */
  this.hasEligibleMemberships = function(member) {
    const memberships = this.getEligibleMemberships(member);
    return (memberships.length > 0);
  };

  /**
   * Get eligible memberships for a member
   */
  this.getEligibleMemberships = function(member) {

    //Get primary member and existing memberships
    const {primaryMember, preSelectedMembership} = this.registration;
    const existing = member.memberships;

    //Initial filter, must be selectable for registration
    const memberships = this.memberships
      .filter(m => m.canSelectWhenRegistering)
      .filter(m => !m.onlyViaDirectLink || m.id === preSelectedMembership)
      // .filter(m => !existing.some(e => e.id === m.id))
      .filter(m => m.appliesToMemberships(existing));

    //Primary member, filter out linked memberships
    if (member === primaryMember) {
      return memberships
        .filter(m => !m.isLinked);
    }

    //Get bundled memberships
    const bundled = primaryMember.memberships
      .filter(m => m.hasNonArchivedLinked);

    //Secondary member, additional filtering
    return memberships
      .filter(m => (

        //Primary non bundled
        (!m.isLinked && !m.hasLinked) ||

        //Linked
        (
          m.isLinked &&
          bundled.some(primary => m.isLinkedTo(primary)) &&
          !existing.some(e => e.isLinked && e.isLinkedTo(m.primary)) &&
          !this.hasReachedBundleLimit(m)
        )
      ));
  };

  /**
   * Get valid memberships to keep (after removal)
   */
  this.getValidMembershipsToKeep = function(member) {

    //Get primary member and existing memberships
    const {primaryMember} = this.registration;
    const existing = member.memberships;

    //Initial filter, must be selectable for registration
    const memberships = this.memberships
      .filter(m => m.canSelectWhenRegistering)
      .filter(m => m.appliesToMemberships(existing));

    //Primary member, filter out linked memberships
    if (member === primaryMember) {
      return memberships
        .filter(m => !m.isLinked);
    }

    //Get bundled memberships
    const bundled = primaryMember.memberships
      .filter(m => m.hasNonArchivedLinked);

    //Secondary member, additional filtering
    return memberships
      .filter(m => (

        //Primary non bundled
        (!m.isLinked && !m.hasLinked) ||

        //Linked
        (
          m.isLinked &&
          bundled.some(primary => m.isLinkedTo(primary))
        )
      ));
  };

  /**
   * Check if we have linked memberships for a primary
   */
  this.hasLinkedMemberships = function(membership) {
    return this.registration.secondaryMembers
      .some(member => member.memberships
        .some(m => m.isLinked && m.isLinkedTo(membership)));
  };

  /**
   * Check if has reached bundle limit
   */
  this.hasReachedBundleLimit = function(membership) {

    //Check bundle limit
    const {id, bundleLimit} = membership;
    if (!bundleLimit) {
      return false;
    }

    //Count existing
    const num = this.registration.secondaryMembers
      .filter(linked => linked.memberships.some(m => m.id === id))
      .length;

    //Check if reached
    return (num >= bundleLimit);
  };

  /**
   * Add membership
   */
  this.addMembership = function(member) {

    //Get data
    const {club, registration} = this;
    const memberships = this.getEligibleMemberships(member);

    //Create handler
    const handler = (membership, subscription) => {
      member.memberships.push(membership);
      member.subscriptions.push(subscription);
      registration.determineSteps();
    };

    //Show modal
    $modal.open('addMembership', {locals: {
      club, memberships, member, handler,
    }});
  };

  /**
   * Remove membership
   */
  this.removeMembership = function(member, membership) {

    //Find indices
    const {id} = membership;
    const i = member.memberships.findIndex(m => m.id === id);
    const j = member.subscriptions.findIndex(s => s.membership.id === id);

    //Remove
    if (i !== -1) {
      member.memberships.splice(i, 1);
    }
    if (j !== -1) {
      member.subscriptions.splice(j, 1);
    }

    //Determine steps
    this.registration.determineSteps();

    //Validate remainder of memberships
    this.validateRemainingMemberships();
  };

  /**
   * Validate remaining memberships
   */
  this.validateRemainingMemberships = function() {

    //Process each member
    for (const member of this.registration.members) {
      if (member.memberships.length === 0) {
        continue;
      }
      const eligible = this.getValidMembershipsToKeep(member);
      for (const membership of member.memberships) {
        if (!eligible.some(e => e.id === membership.id)) {
          return this.removeMembership(member, membership);
        }
      }
    }
  };

  /**
   * Validate
   */
  this.validate = function() {

    //Reset
    this.isErrorMembershipRequired = false;

    //Must select membership
    if (Settings.get('registration.requiresMembershipSelection')) {
      if (this.registration.members.some(m => m.memberships.length === 0)) {
        this.isErrorMembershipRequired = true;
        return;
      }
    }

    //Get bundled memberships without linked ones
    const membership = this.registration.primaryMember.memberships
      .filter(m => m.hasNonArchivedLinked)
      .find(m => !this.hasLinkedMemberships(m));

    //Set flag and handler
    const hasLinkedMembers = this.registration.secondaryMembers.length > 0;
    const handler = () => {
      if (!hasLinkedMembers) {
        return this.back();
      }
      else {
        return;
      }
    };

    //Found one?
    if (membership) {
      return $modal
        .open('basic', {
          templateUrl: 'portal/register/modals/no-linked-members.html',
          rejectOnDismissal: true,
          locals: {membership, hasLinkedMembers, handler},
        })
        .result
        .then(() => this.continue())
        .catch(error => {
          if (error !== 'cancel') {
            throw error;
          }
        });
    }

    //Continue
    this.continue();
  };
});
