
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.ForgotPassword.Controller', [])

/**
 * Controller
 */
.controller('ForgotPasswordCtrl', function(
  $state, User, NotFoundError, ValidationError, Config
) {

  /**
   * On init
   */
  this.$onInit = function() {

    //Initialize model
    this.model = {
      username: '',
    };

    //Initialize flags
    this.isSubmitting = false;
    this.isSubmitted = false;

    //Check if sign in is enabled
    if (this.club && !this.club.settings.signIn.canUseCredentials) {
      $state.go('login');
    }

    setTimeout(() => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.render('captcha-container', {
          sitekey: Config.google.recaptchaKey,
          callback: this.submit.bind(this),
          action: 'password_reset',
          size: 'invisible',
        });
      });
    });
  };

  /**
   * Submit request
   */
  this.submit = function() {

    //Must be validated
    if (this.form.$invalid) {
      return;
    }

    //Mark as submitting
    this.isSubmitting = true;
    this.isUnknown = false;
    this.isInvalid = false;

    //Check if captcha is valid
    this.model.captchaResponse = window.grecaptcha.enterprise.getResponse();
    if (!this.model.captchaResponse) {
      window.grecaptcha.enterprise.execute();
      return;
    }

    //Send request
    User
      .forgotPassword(this.model)
      .then(() => {
        this.isSubmitted = true;
        this.model = {};
      })
      .catch(error => {
        if (error instanceof NotFoundError) {
          this.isUnknown = true;
        }
        if (error instanceof ValidationError) {
          this.isInvalid = true;
        }
        throw error;
      })
      .finally(() => {
        this.isSubmitting = false;
        window.grecaptcha.enterprise.reset();
      });
  };
});
